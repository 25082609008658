
import imageCompression from "browser-image-compression";
const defaultOptions = {
  maxSizeMB: 0.8,
};
export const Map_Api_Key = "AIzaSyD0Kjrft-YjyFDwi6n3IDzaEEtykel_Jiw";

export function getCookie(key) {
    let cookie = localStorage.getItem(key);

    return cookie;
}

export function setCookie(key, value) {
    localStorage.setItem(key, value)

    return null;
}

export function deleteCookie(key) {
    localStorage.removeItem(key)
    return null;
}

export function setPermitList(key, value) {
    localStorage.setItem(key, JSON.stringify(value));

    return null;
}

export function getPropertyValue(object, dataToRetrieve) {
    dataToRetrieve.split('.').forEach(function(token) {
      if (object) object = object[token];
    });
    return object;
}

export function compressFile(imageFile, options = defaultOptions) {
    return imageCompression(imageFile, options);
  }

  export function readFileAsBase64(file) {
    return imageCompression.getDataUrlFromFile(file);
  }

  export function blobToFile(theBlob, fileName){
    return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
}


