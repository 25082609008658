import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes,Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import PrivateRoutes from './utils/PrivateRoutes';
import 'react-toastify/dist/ReactToastify.css';
import './scss/style.scss'
import '../src/assets/css/custom.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '@fortawesome/fontawesome-free/css/all.min.css';

const loading = (
  <div className="pt-3 text-center">
    <div title="loading" className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pagesm
const Login = React.lazy(() => import('./views/Login/Login'))
// const Register = React.lazy(() => import('./views/Register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))

class App extends Component {
  render() {
    return (
      <>
     <ToastContainer />
        <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            {/* <Route exact path="/register" name="Register Page" element={<Register />} /> */}
            <Route element={<PrivateRoutes />}>
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/" element={<Navigate to="dashboard" replace />} />
            <Route exact path="*" element={<DefaultLayout />} />
            </Route>

          </Routes>
        </Suspense>
      </BrowserRouter>
      </>

    )
  }
}

export default App
